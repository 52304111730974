<template lang="pug">
div(class='sticky top-0 z-50 bg-primary border-b-2 border-primary-dark p-3')
  div(class='lg:container')
    div(class='flex justify-between items-center')
      CartManager

      p(class='flex items-center text-sm')
        | {{ username }}
        button(class='ml-2 text-xs hover:font-bold' @click='logout') (Sign Out)
</template>

<script setup lang="ts">
const { $cognito } = useNuxtApp()

const username = ref('')

onMounted(() => {
  username.value = $cognito.getUsername()
})

function logout() {
  $cognito.logout()
}
</script>
